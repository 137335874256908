import React from 'react';
import { AlertCircle } from 'react-feather';

import { Button } from '@axeedge/go-pupil-components';

import VotingPanel from '../VotingPanel';

const VotingInstructions = ({ closePanel }) => {
    return (
        <VotingPanel allowDismiss={true} closePanel={closePanel} icon={AlertCircle} title='StarCasting'>
            <h2 className='heavy'>It's time to pick the best entry</h2>
            <p>First, read the entry we've chosen at random.</p>
            <p>Next give it a star rating based on how well the response answers the question.</p>
            <h2 className='heavy'>Go do it again...</h2>
            <p>Don’t stop there - keep on reading and StarCasting. The next one you read could be the one most similar to how your teacher would respond, or a new way of thinking about the mathematical concept.</p>
            <Button primary onClick={closePanel}>Ok, Got It</Button>
        </VotingPanel>
    )
}

export default VotingInstructions;
