import React, { useState, Fragment, useContext } from 'react';
import { AlertCircle, XCircle, File, Eye } from 'react-feather';
import cx from 'classnames';

import { PupilAvatarViewer as Viewer } from '@axeedge/go-shared-components';
import { AuthContext } from '../../../../../../services/user/AuthProvider';
import styles from './Guidance.module.scss';

const Guidance = ({ isFeedback, title, text, textBlocks = null, resources = null }) => {
    const [guidanceMobile, setGuidanceMobile] = useState(false);

    const toggleGuidanceInfo = () => {
        guidanceMobile ? setGuidanceMobile(false) : setGuidanceMobile(true);
    }

    const { currentUser: user } = useContext(AuthContext);

    const renderGuidance = () => {
        return (
            <Fragment>
                <h3 className={styles.guidanceTitle}><AlertCircle size="30" /> {title}</h3>
                {text && <p className={styles.guidanceContent}>{text}</p>}
                {textBlocks && <div className={styles.guidanceContent}>{textBlocks}</div>}
            </Fragment>
        )
    }

    return (
        <div className={cx(styles.guidance, { [styles.guidanceFeedback]: isFeedback })}>
            <div onClick={toggleGuidanceInfo} className={styles.guidanceInfoIcon}>
                <AlertCircle />
            </div>
            <div className={styles.guidanceAvatar}>
                {/* <Viewer styleName={styles.guidanceAvatarImg} avatar={JSON.parse(user.avatarJson)} /> */}
            </div>
            <div className={styles.guidancePanel}>
                {renderGuidance()}
                {resources && resources.length > 0 &&
                    <>
                        <p>Check out the following resources</p>
                        {resources.map(res => {
                            return (
                                <div key={res.id || res.name} className={styles.taskFile}>
                                    {res.url.includes('amazon') && <a className={styles.taskFileView} href={res.url} rel="noopener noreferrer" target='_blank'>                                <File />
                                        <span className={styles.taskFileName}>{res.name}</span><Eye color="#9DC030" className={styles.taskFileEye} /></a>}
                                </div>
                            )
                        })}
                    </>
                }
            </div>
        
            {
                guidanceMobile && (
                    <div className={styles.guidanceMobile}>
                        <div className={styles.guidanceMobileInner}>
                            <div onClick={toggleGuidanceInfo} className={styles.guidanceMobileClose}><XCircle size="30" /></div>
                            <div className={styles.guidanceMobileAvatarHolder}>
                                <div className={styles.guidanceMobileAvatar}>
                                    <Viewer styleName={styles.guidanceMobileAvatarImg} avatar={JSON.parse(user.avatarJson)} />
                                </div>
                            </div>
                            <div className={cx(styles.guidancePanel, styles.guidanceMobilePanel)}>
                                {renderGuidance()}
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Guidance;
