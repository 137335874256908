import React, {useEffect, useState} from 'react';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import Book from '../components/Book';

const Books = ({ chapters }) => {

    const [tasks,setTasks] = useState([])

    useEffect(() => {
        const newTasks = []
        chapters.map(book => {
            [...book.chapters, ...book.polls].map(item => {
                if (item.question) {
                    item.status === 2 && newTasks.push(item)
                } else if (item.status >= CHAPTER_STATUS.completed)  {
                    newTasks.push(item)
                }
            })
        })
        setTasks(newTasks.sort(function(a,b){return new Date(b.createdAt) - new Date(a.createdAt);}))
    },[])

    if (chapters.length === 0) {
        return <p>You've not completed any maths projects yet.</p>
    }
    // return chapters.map(chapter => {
    //     return chapter.chapters.map(chapter => {
    //         return <Book key={chapter.id} chapter={chapter} />
    //     })
    // })
    return tasks.map(item => {
        if (item.question) {
            return <Book key={item.id} poll={item} />
        } else {
            return <Book key={item.id} chapter={item} />
        }    
    })
}

export default Books;