import React, { Fragment, useState } from 'react';
// import { Link } from '@reach/router';
import { XCircle } from 'react-feather';
import logo from '../../../../images/boomwriter_reversed.svg';
import bookImg from '../../../../images/book.png';
import ocBookImg from '../../../../images/oc-book.jpg';
import NavLinks from './components/NavLinks';

const BookMenu = ({ chapter, setSelectedChapter, selectedChapter }) => {
    
    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMenu = () => {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
    }

    const closeMenu = () => {
        setMobileMenu(false) ;
    }

    return(
        <Fragment>
            <div className="menu-header">
                <div onClick={toggleMenu} className="menu-header__brand">
                    <span className="menu-header__brand__toggle"></span>
                    <img src={logo} alt="BoomWriter" />
                </div>
                {
                    mobileMenu && (
                    <div className="menu-header__nav">
                        <div className="menu-header__nav__links">
                            <NavLinks chapter={chapter} closeMenu={closeMenu} setSelectedChapter={setSelectedChapter} selectedChapter={selectedChapter} />
                        </div>
                        <div className="menu-header__nav__content">
                            <div className="menu-header__nav__content__top">
                                <img className="menu-header__nav__content__top__img" src={ocBookImg} alt="" />
                                <span className="menu-header__nav__content__top__close"><XCircle onClick={toggleMenu} size="35" /></span>
                            </div>
                            <h1>{chapter.template.name}</h1>
                            <div className="menu-header__nav__content__base">
                                <p className="lead u-mb-0">
                                    {chapter.project.studentsClass.teachers[0] && chapter.project.studentsClass.teachers[0].displayName}
                                </p>
                                <p className="lead">{chapter.project.studentsClass.name}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="menu-sidebar-book">
                <div className="menu-sidebar__nav">
                    <NavLinks chapter={chapter} closeMenu={closeMenu} setSelectedChapter={setSelectedChapter} selectedChapter={selectedChapter} />
                </div>
            </div>
        </Fragment>
    )
}

export default BookMenu;
