// This will check to see if there's a token, set the token if so, and check internal log in is alight
import React, { Fragment, useContext, useEffect, useState } from 'react';
import ls from 'local-storage';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { parse } from 'query-string';

import { Loader, RewardNotification } from '@axeedge/go-pupil-components';
import { ErrorPage } from '@axeedge/go-shared-components';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';

import { AuthContext } from '../../../services/user/AuthProvider';
import { ERROR, LOGGED_IN } from '../services/graphql';
import { APP_NAME } from '../../../services/constants';
import UserQueryHolder from './UserQueryHolder';
import RouteCapture from './RouteCapture';
import AppMenu from '../../../components/AppMenu';
import { ActionCableWrapper, NotifCableConsumer } from './ActionCableWrapper';

const TokenCheck = ({ children, location }) => {
    const [reward, setReward] = useState(null);
    const [token, setToken] = useState(ls(getAppTokenName(APP_NAME)));
    const [loggingOut, setLoggingOut] = useState(false);
    const { data, error, loading } = useQuery(LOGGED_IN);
    const { data: clientError } = useQuery(ERROR);
    const client = useApolloClient();
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const onLogout = () => {
        setLoggingOut(true);
        setToken(null);
        ls.clear();
        setCurrentUser(null);
        client.writeData({
            data: {
                loggedIn: false
            }
        });
        window.location.href = `${getUrl(APPS.go_portal_pupil)}/logout`;
        //window.location.href = `${getUrl(APPS.go_portal_pupil)}/`;
    }

    useEffect(() => {
        const tokenInUrl = parse(location.search).token;
        if ((tokenInUrl !== undefined && tokenInUrl !== token && !loggingOut) || (!token && !loggingOut)) {
            if (tokenInUrl) {
                ls.clear();
                setToken(tokenInUrl);
                ls(getAppTokenName(APP_NAME), tokenInUrl);
                client.writeData({
                    data: {
                        loggedIn: tokenInUrl,
                        forcedLogout: false
                    }
                });
            } else {
                window.location.href = `${getUrl(APPS.go_portal_pupil)}/redirectAfterLogin/${APP_NAME}?targetUrl=${location.pathname}`;
            }
        }
    }, [token, client, location.pathname, location.search, loggingOut])

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    if (data && data.loggedIn) {
        return (
            <Fragment>
                <RouteCapture />
                <UserQueryHolder />
                {
                    currentUser && (
                        <ActionCableWrapper>
                            <NotifCableConsumer id={currentUser.id} onLogout={onLogout} onReward={reward => setReward(reward)} />
                            <div className='app-container'>
                                <AppMenu onLogout={onLogout} user={currentUser} />
                                <div className='app-container__content'>
                                    {children}
                                </div>
                            </div>
                            {reward && <RewardNotification reward={reward} clearReward={() => setReward(null)} />}
                        </ActionCableWrapper>
                    )}
            </Fragment>
        )
    }

    return null;
}

export default TokenCheck;
