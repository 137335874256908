import React, { Fragment, useState } from 'react'
// import { useEffectOnce } from 'react-use';

// import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import BookMenu from './BookMenu';
// import BookHeader from './BookHeader';
import Chapter from './Chapter';
import BookInfo from './BookInfo';
import styles from './Chapter/Chapter.module.scss'
import { Link } from '@reach/router';
import {XCircle} from 'react-feather'
const BookHolder = ({ chapterData }) => {

    const [selectedChapter, setSelectedChapter] = useState(0)
    const [seenChapter, setSeenChapter] = useState(null)
    return (
        <div className={styles.mainContainer}>
        <div className={styles.upper}>
            <Link to="/" className={styles.closeBtn}><span className={styles.closeBtnTxt}>Close</span> <XCircle /></Link>
        </div>
            <div className='book-wrapper'>
                <BookMenu chapter={chapterData} selectedChapter={selectedChapter} setSelectedChapter={setSelectedChapter} />
                {<div className="content-panel">
                    {
                        selectedChapter !== -1 ? (
                            <Fragment>
                                <Chapter chapter={chapterData} setSeenChapter={setSeenChapter} setSelectedChapter={setSelectedChapter}/>
                            </Fragment>
                        ) :
                            <BookInfo setSelectedChapter={setSelectedChapter} chapter={chapterData} />
                    }
                </div> }
            </div>
        </div>
    );
}

export default BookHolder;
