import { gql } from 'apollo-boost';

const studentEntryQuery = `
id
castedCounter
feedback
status
submittedAt
teacherEdit
entry
winner
seenWinner
author {
    id
    displayName
    firstName
    lastName
    name
}
`;
/*add vocabularyWordsUsed back into studentEntryQuery */

const chapterQuery = `
id
content
description
guidance
orderid
status
title
classBook {
    id
    isSingleChapter
    classPack {
        id
        isWritingActivity
    }
    studentsClass {
        id
        name    
        teachers {
            id
            displayName
        }
    }
    bookTemplate {
        id
        coverUrl
    }
}
currentStudentEntry {
    ${studentEntryQuery}
}
currentStudentNextEntryToStarCast {
    id
    text
}
studentEntries {
    id
    winner
    seenWinner
    teacherEdit
    text
    author {
        id
        name
    }
}
vocabularyWords
`;

export const GET_CLASS_BOOK = gql`
query classBook($classBookId: ID!) {
    classBook(classBookId: $classBookId) {
        id
        currentChapter
        isSingleChapter
        status
        title
        bookTemplate {
            id
            name
            coverUrl
            resources {
                id
                name
                url
                resourceType
            }
        }
        classPack {
            id
            isWritingActivity
        }
        chapters {
            ${chapterQuery}
        }

        studentsClass {
            id
            name
            teachers {
                id
                displayName
            }
            school {
                id
                name
            }
        }
    }
}
`;

export const GET_MATH_PROJECT = gql`
query mathProject($mathProjectId: ID!) {
    mathProject(mathProjectId: $mathProjectId) {
        id
        name
        topic {
            id
            name
            description
        }
        chapters {
            id
            guidance
            status
            title
            starcastingEnabled
            currentStudentNextEntryToStarCast {
                id
                entry
            }
            template {
                id
                name
                isSocial
            }
            currentStudentEntry {
                ${studentEntryQuery}
            }
            words {
                id
                word
            }
            entries {
                id
                winner
                seenWinner
                teacherEdit
                entry
                author {
                    id
                    name
                }
            }
        }
        studentsClass {
            id
            name
            teachers {
                id
                displayName
            }
            school {
                id
                name
            }
        }
    } 
}
`

export const GET_MATH_PROJECT_CHAPTER = gql`
query mathProjectChapter($mathProjectChapterId: ID!) {
    mathProjectChapter(mathProjectChapterId: $mathProjectChapterId) {
        id
        guidance
        status
        title
        starcastingEnabled
        currentStudentNextEntryToStarCast {
            id
            entry
        }
        template {
            id
            name
            isSocial
        }
        currentStudentEntry {
            ${studentEntryQuery}
        }
        words {
            id
            word
        }
        entries {
            id
            winner
            seenWinner
            teacherEdit
            entry
            author {
                id
                name
            }
        }
        project {
            id
            name
            topic {
                id
                name
                description
            }
            studentsClass {
                id
                name
                teachers {
                    id
                    displayName
                }
                school {
                    id
                    name
                }
            }
        }
    }
}`


export const GET_STUDENT_ENTRY = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        ${studentEntryQuery}
    }
}
`;

export const SAVE_DRAFT_MUTATION = gql`
mutation saveEntry($studentEntryId: ID!, $text: String!) {
    saveEntry(studentEntryId: $studentEntryId, text: $text) {
        studentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;

export const HAND_IN_ENTRY_MUTATION = gql`
mutation handInChapterEntry($studentEntryId: ID!, $text: String!) {
    handInEntry(studentEntryId: $studentEntryId, text: $text) {
        studentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;

export const CAST_MUTATION = gql`
mutation starCastEntry($studentEntryId: ID!, $stars: Int!) {
    starCastEntry(studentEntryId: $studentEntryId, stars: $stars) {
        chapter {
            id
            currentStudentNextEntryToStarCast {
                id
                entry
            }
        }
        errors
    }
}`;


export const SEE_CHAPTER_WINNER = gql`
mutation seeChapterWinner($bookChapterStudentEntryId: ID!) {
    seeChapterWinner(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;
