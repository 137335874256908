import React from 'react';
// import { Button } from '@axeedge/go-pupil-components';
// import { BOOK_STATUS } from '@axeedge/go-shared-utils';
// import noCover from '../../../../images/no-cover.png';
import Guidance from '../Chapter/components/Guidance';
import styles from './BookInfo.module.scss';
import styles2 from '../Chapter/Chapter.module.scss';

const BookInfo = ({ chapter, setSelectedChapter }) => {

    //const [completed] = useState(book.status >= BOOK_STATUS.completed)
    const getGuidanceText = () => {
        return (
            <>
                <p>Follow your teacher's instructions carefully for this piece of writing and make sure you try your best!'</p>
                <p>Good luck!</p>
            </>
        )
    }



    return (
        <div className={styles.book}>
            <div className={styles2.chapter}>
                <div className={styles2.chapterEntry}>
                    <div >
                        <div className={styles.bookHeader}>
                            <div className={styles.bookHeaderMeta}>
                                <h3>{chapter.template.name}</h3>
                                <div>
                                    <p className="u-mb-0">{chapter.project.studentsClass.school.name}</p>
                                    <p className="u-mb-0">{chapter.project.studentsClass.teachers[0] && chapter.project.studentsClass.teachers[0].displayName}</p>
                                </div>
                            </div>
                        </div>
                        {/* {
                            completed && (
                                <Fragment>
                                    <h2 className="h1 heavy">Well done!</h2>
                                    <p>Your book is complete. Click on the winning chapters you would like to re-read. These will be the contents of your book.</p>
                                    <ul className={styles.bookChapters}>
                                        {book.chapters.map((chapter, i) => (
                                            <li key={`chapter-${i}`}><Button onClick={() => setSelectedChapter(chapter.orderid)} className={styles.bookInfoChaptersLink}>Chapter {i + 1}</Button></li>
                                        ))}
                                    </ul>
                                </Fragment>
                            )} */}
                    </div>
                </div>

                <div className={styles2.chapterSide}>
                    {/* {
                        completed ? (
                            <div className={styles.bookCover}>
                                <img src={book.bookTemplate.coverUrl || noCover} alt={`Book cover for ${book.title || book.bookTemplate.name}`} />
                            </div>
                        ) : (
                            <>
                                <Guidance title={'Info'} text={null} textBlocks={getGuidanceText()} />
                            </>
                        )} */}
                        <Guidance title={'Info'} text={null} textBlocks={getGuidanceText()} />
                </div>

            </div>

        </div>


    );
}

export default BookInfo;
