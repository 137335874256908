import React, { useContext } from 'react';
import styles from './InfoPanel.module.scss';
import cx from 'classnames';
import {PupilAvatarViewer as Viewer} from '@axeedge/go-shared-components';
import { AuthContext } from '../../../../services/user/AuthProvider';

const InfoPanel = ({ children, whitePanel, title, icon: Icon }) => {

    const { currentUser: user } = useContext(AuthContext);

    
    return(
        <div className={styles.infoContainer}>
            <div className={styles.infoAvatar}>
                {/* <Viewer styleName={styles.infoAvatarImg} avatar={JSON.parse(user.avatarJson)} /> */}
            </div>
            <div className={cx(styles.infoPanel,  {[styles.infoPanelWhite]: whitePanel})}>
                <h3 className={styles.infoTitle}><Icon /> {title}</h3>
                {children}
            </div>
        </div>
    )
}

export default InfoPanel;
