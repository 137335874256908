import { gql } from 'apollo-boost';

export const GET_POLL = gql`
    query poll($id: ID!) {
        poll(id: $id) {
            id
            question
            status
            allowComments
            pollEntries {
                id
                status
                pollReaction {
                    id
                    reaction
                }
                entry
                author {
                    id
                }
            }
            pollReactionSet {
                id
                reactionSet
                pollReactions {
                    id
                    reaction
                }
            }
        }
    }
`;

export const SUBMIT_POLL = gql`
    mutation createPollEntry($pollId: ID!, $pollReactionId: ID!, $entry: String) {
        createPollEntry(pollId: $pollId, pollReactionId: $pollReactionId, entry: $entry) {
            pollEntry {
                id
            }
            errors
        }
    }
`