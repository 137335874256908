import React, { Fragment, useState } from 'react';
import { Link } from '@reach/router';
import { AlertCircle, CheckCircle } from 'react-feather';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import { Button } from '@axeedge/go-pupil-components';

import Header from '../Header';
import VotingPanel from '../VotingPanel';
import RatingsPanel from './components/RatingsPanel';
import StarsHolder from './components/StarsHolder';
import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import VotingInstructions from '../VotingInstructions';
import BookHeader from '../../../BookHeader/index'
import styles from '../../Chapter.module.scss';

const Voting = ({ chapter }) => {
    const [hasntVoted, setHasntVoted] = useState(chapter.currentStudentEntry.castedCounter === 0);
    const [showVoted, setShowVoted] = useState(false);
    const [entry, setEntry] = useState(chapter.currentStudentNextEntryToStarCast);
    const onRatingCompleted = (newEntry, rating) => {
        setEntry(newEntry);
        setShowVoted(rating);
    }

    return (
        <Fragment>
            {
                chapter.status === CHAPTER_STATUS.voting_closed && (
                    <VotingPanel  icon={AlertCircle} title='StarCasting'>
                        <h2 className='heavy'>StarCasting has closed</h2>
                        <p>We are crunching the numbers to see which entry has the most stars.</p>
                        <p>Stay tuned to find out who won</p>
                    </VotingPanel>
                )
            }

            {hasntVoted && chapter.status === CHAPTER_STATUS.voting && <VotingInstructions closePanel={() => setHasntVoted(false)} />}

            {
                (showVoted || chapter.currentStudentNextEntryToStarCast === null) && chapter.status === CHAPTER_STATUS.voting &&  (
                    <VotingPanel icon={CheckCircle} title='Great Job!'>
                        {showVoted  && (
                            <Fragment>
                                <h2 className='heavy u-my-3'>You awarded {showVoted} stars to the entry</h2>
                                <StarsHolder
                                    displayOnly={true}
                                    enabled={true}
                                    rating={showVoted}
                                />
                            </Fragment>
                        )}

                        { entry ? (
                            <Fragment>
                                <h2 className='heavy u-mt-4'>You make it happen</h2>
                                <p>You and your class are in control of finding the winner. So don't stop here, StarCast again on a different entry and you could find the winner</p>
                                <div className='u-my-2'>
                                    <Button
                                        primary
                                        wide
                                        onClick={() => {
                                            setShowVoted(false);
                                        }}
                                    >
                                        Let's Go!
                                    </Button>
                                </div>
                                <Link to='/'>No thanks, I'm done.</Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2 className='heavy u-mt-4'>Amazing, you’ve rated them all!</h2>
                                <p>Nice job, stay tuned to find out who won...</p>
                            </Fragment>
                        )}
                    </VotingPanel>
                )
            }

            {
                !hasntVoted && !showVoted && chapter.status === CHAPTER_STATUS.voting && entry && (
                    <div className={styles.chapter}>
                        <div className={styles.chapterEntry}>
                        <BookHeader chapter={chapter} teachers={chapter.project.studentsClass.teachers} classTitle={chapter.project.studentsClass.name} bookName={chapter.template.name} />
                            <div className={styles.chapterEntryContent}>
                                <Header title={chapter.title} author={'by ???'} />
                                <EditorJsOutput data={JSON.parse(entry.entry)} />
                            </div>
                        </div>

                        <div className={styles.chapterSide}>
                            <h3 className='heavy'>Rate this entry...</h3>
                            <div className={styles.chapterSideActions}>
                                <RatingsPanel entryId={entry.id} onRatingCompleted={onRatingCompleted} />
                            </div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}

export default Voting;
