import React, { Fragment, useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Modal } from '@axeedge/go-pupil-components';
import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import Tabs from '../../../../../../components/Tabs';
import Tab from '../../../../../../components/Tab';
import VocabModule from '../VocabModule';
import { highlightEntry } from '../../../../services/utils';

import { SEE_CHAPTER_WINNER, GET_STUDENT_ENTRY } from '../../../../services/graphql';
import styles from '../../Chapter.module.scss';
import BookHeader from '../../../BookHeader/index'

const Completed = ({ chapter, setSeenChapter }) => {
    const winnerEntry = chapter.entries.find(entry => { return entry.winner });
    const [showWinner, setShowWinner] = useState(!chapter.currentStudentEntry.winner);
    const [errorModal, setErrorModal] = useState(null);

    const client = useApolloClient();

    // const [seeChapterWinner] = useMutation(SEE_CHAPTER_WINNER, {
    //     onCompleted: ({ seeChapterWinner }) => {
    //         if (seeChapterWinner.errors && seeChapterWinner.errors.length !== 0) {
    //             setErrorModal(seeChapterWinner.errors[0])
    //             return;
    //         } else {
    //             client.writeQuery({
    //                 query: GET_STUDENT_ENTRY,
    //                 variables: {
    //                     id: seeChapterWinner.bookChapterStudentEntry.id
    //                 },
    //                 data: {
    //                     studentEntry: seeChapterWinner.bookChapterStudentEntry
    //                 }
    //             })
    //             setSeenChapter(chapter.orderid)
    //         }
    //     }
    // });

    /*useEffect(() => {
        if ((winnerEntry && !chapter.currentStudentEntry.seenWinner)) {
            seeChapterWinner({
                variables: {
                    bookChapterStudentEntryId: winnerEntry.id
                }
            });
        }
    }, [chapter.currentStudentEntry, winnerEntry, seeChapterWinner]);*/

    useEffect(() => {
        setShowWinner(!chapter.currentStudentEntry.winner)
    }, [chapter.orderid]);

    const renderEntry = entry => {
        return (
            <Fragment>
                {
                    entry ? (
                        <Fragment>
                            <h4 className='h2 heavy'>{entry.author.id === chapter.currentStudentEntry.author.id ? 'By you' : entry.author.name}</h4>
                            {
                                entry.entry ? (
                                    <EditorJsOutput data={highlightEntry(JSON.parse(entry.winner ? entry.teacherEdit || entry.entry : entry.entry), chapter.words)} />
                                ) : (
                                    <p>No entry submitted for this chapter</p>
                                )
                            }
                        </Fragment>
                    ) : (
                        <p>No entry data</p>
                    )
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={styles.chapter}>
                <div className={styles.chapterEntry}>
                <BookHeader chapter={chapter} teachers={chapter.project.studentsClass.teachers} classTitle={chapter.project.studentsClass.name} bookName={chapter.template.name} />
                    <div className={styles.chapterEntryContent}>
                        <h3 className='h2 heavy u-text-primary-dark'>{chapter.title}</h3>
                        {
                            !chapter.currentStudentEntry.winner && (
                                <Tabs className={styles.chapterEntryTabs}>
                                    <Tab active={showWinner} onClick={() => setShowWinner(true)}>Winner</Tab> 
                                    <Tab active={!showWinner} onClick={() => setShowWinner(false)}>Your entry</Tab>
                                </Tabs>
                            )
                        }
                        {showWinner ? renderEntry(winnerEntry) : renderEntry(chapter.currentStudentEntry)}
                    </div>
                </div>

                <div className={styles.chapterSide}>
                    {chapter.words && chapter.words.length > 0 && (winnerEntry.entry || chapter.currentStudentEntry?.entry) &&
                        <>
                            {showWinner ? (
                                <VocabModule entry={JSON.parse(winnerEntry.entry)} vocab={chapter.words} />

                            ) : <>{chapter.currentStudentEntry?.entry && <VocabModule entry={JSON.parse(chapter.currentStudentEntry.entry)} vocab={chapter.words} />}</>
                            }
                        </>
                    }
                </div>

                {
                    errorModal && (
                        <Modal closeModal={() => setErrorModal(null)}>
                            {errorModal}
                        </Modal>
                    )
                }
            </div>
        </Fragment>
    )
}

export default Completed;
