import React, { Fragment, useState } from 'react';
import useTimeout from 'use-timeout';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';

import { Button, Modal } from '@axeedge/go-pupil-components';
// import { ENTRY_RATINGS_LABELS } from '@axeedge/go-shared-utils';

import StarsHolder from '../StarsHolder';
import { CAST_MUTATION } from '../../../../../../services/graphql';

import styles from './RatingsPanel.module.scss';
import animatedClock from '../../../../../../../../images/animated-clock.svg';
import { TIMER_LENGTH } from './services/timer';

const RATINGS_LABELS = [
    '',
    'Almost there',
    'OK',
    `Enjoyable`,
    'Great',
    'Incredible!'
];

const RatingsPanel = ({ entryId, onRatingCompleted }) => {
    const [rating, setRating] = useState(0);
    const [tempRating, setTempRating] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [errorModal, setErrorModal] = useState(null);

    useTimeout(() => setEnabled(true), TIMER_LENGTH);

    const [cast, { loading }] = useMutation(CAST_MUTATION, {
        variables: {
            studentEntryId: entryId,
            stars: rating
        },
        onCompleted: ({ starCastEntry }) => {
            if (starCastEntry.errors && starCastEntry.errors.length !== 0) {
                setErrorModal(starCastEntry.errors[0])
            } else {
                onRatingCompleted(starCastEntry.chapter.currentStudentNextEntryToStarCast, rating);
            }
        }
    })

    return (
        <Fragment>
            <p className={cx(styles.title, {[styles.titleDisabled]: !enabled})}>How accurately does this entry answer the mathematical question?</p>
            <StarsHolder
                enabled={enabled}
                labels={RATINGS_LABELS}
                rating={tempRating || rating}
                setRating={setRating}
                setTempRating={setTempRating}
            />


            {
                enabled ? (
                    <p className={cx(styles.ratingText, {[styles.ratingTextDisabled]: !enabled})}>{rating === 0 && !tempRating ? 'select your rating' : tempRating ? RATINGS_LABELS[tempRating] : RATINGS_LABELS[rating]}</p>
                ) : (
                    <img src={animatedClock} alt="Reading timer animation" className='u-mx-auto u-my-2' />
                )
            }

            <Button primary fullWidth onClick={() => cast()} disabled={!rating || loading}>Rate it!</Button>

            {
                errorModal && (
                    <Modal closeModal={() => setErrorModal(null) }>
                        {errorModal}
                    </Modal>
                )
            }
        </Fragment>
    );
}

export default RatingsPanel;
