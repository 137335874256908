// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Dashboard data
export const MAIN_DASHBOARD_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        name
        school {
            id
            name
        }
        mathProjects {
            id
            name
            topic {
                id
                name
                description
            }
            polls {
                id
                status
                question
                createdAt
            }
            chapters {
                id
                status
                createdAt
                template {
                    id
                    name
                }
            }
        }
    }
}
`;
