import React from 'react';
import { Link } from '@reach/router';
import cx from 'classnames';
import moment from 'moment';

import noCover from '../../../images/no-cover.png';
import taskCover from '../../../images/task.png';

import styles from './Task.module.scss';
import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';

const Book = ({ chapter, poll = false }) => {
    const getSubtitle = () => {
        if (chapter.status >= CHAPTER_STATUS.completed) {
                return moment(chapter.completedAt).format('MMMM YYYY');
        } else {
            if (chapter.status >= CHAPTER_STATUS.completed) {
                return 'Published!'
            }
            if (chapter.status === CHAPTER_STATUS.writing) {
                return 'Write Now!';
            }
            if (chapter.status === CHAPTER_STATUS.writing_closed || chapter.status === CHAPTER_STATUS.voting_ready) {
                return 'Writing has ended!';
            }
            if (chapter.status === CHAPTER_STATUS.voting) {
                return 'Vote Now!'
            }
            if (chapter.status === CHAPTER_STATUS.voting_closed) {
                return 'Voting has ended!';
            }
            return null;
        }
        
    }

    return (
        poll ?
        <Link key={`poll-${poll.id}`} to={`poll/${poll.id}`} className={cx(styles.task, styles.taskPoll)}>
            <div className={styles.taskMain}>
                <h2 className={styles.dashboardBookCardTitle}>{poll.question}</h2>
            </div>
            <div className={styles.taskSide}>
                <p className='u-mb-0'><span className={styles.iconVote}>Poll</span></p>
            </div>
        </Link> :
        <Link key={`book-${chapter.id}`} to={`book/${chapter.id}`} className={styles.task}>
            <div className={styles.taskMain}>
                <h2 className={styles.dashboardBookCardTitle}>{chapter.template.name}</h2>
            </div>
            <div className={styles.taskSide}>
                <p className='u-mb-0'>{getSubtitle()}</p>
            </div>
        </Link>
    );
}

export default Book;