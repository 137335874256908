import React, { useState, useContext } from 'react';
import styles from './BookHeader.module.scss';
import { Book, XCircle, AlertCircle } from 'react-feather';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import VotingInstructions from '../Chapter/components/VotingInstructions';

import noCover from '../../../../images/no-cover.png';
import starsHeader from '../../../../images/stars-header.svg';
import { PupilAvatarViewer as Viewer } from '@axeedge/go-shared-components';
import { AuthContext } from '../../../../services/user/AuthProvider';
import cx from 'classnames';

const BookHeader = ({ chapter, bookName, classTitle = null, teachers = null, chapterTitle }) => {

    const [bookInfo, setBookInfo] = useState(false);
    const [votingGuidance, setVotingGuidance] = useState(false);

    const toggleBookInfo = () => {
        bookInfo ? setBookInfo(false) : setBookInfo(true);
    }

    const { currentUser: user } = useContext(AuthContext);

    const toggleVotingGuidance = () => {
        votingGuidance ? setVotingGuidance(false) : setVotingGuidance(true);
    }

    return (
        <div className={cx(styles.header, { 'u-mb-3': chapter.status !== CHAPTER_STATUS.voting })}>
            <div className={styles.headerTop}>
                <h1 className={styles.headerTopTitle} onClick={toggleBookInfo}><span className={styles.headerInfoIcon}><Book /></span> {bookName}</h1>
                {
                    (chapter.status === CHAPTER_STATUS.voting) && (
                        <div className={styles.headerTopAvatar}>
                            {/*<Viewer styleName={cx(styles.headerTopAvatarImg, styles.headerTopAvatarImgStack)} avatar={JSON.parse(user.avatarJson)} />*/}
                            <img src={starsHeader} alt="" className={styles.headerTopStars} />
                            <span onClick={toggleVotingGuidance} className={styles.headerTopAvatarInfoIcon}><AlertCircle /></span>
                        </div>
                    )
                }

            </div>

            {bookInfo && (
                <div className={styles.headerPanel}>
                    <div className={styles.headerPanelContent}>
                        <div className={styles.headerPanelImageHolder}>
                            {/*<img className={styles.headerPanelImage} src={chapter.classBook.bookTemplate.coverUrl || noCover} alt="" />*/}
                        </div>
                        <div>
                            <span className={styles.headerPanelClose}><XCircle size="35" onClick={toggleBookInfo} /></span>
                            <h2>{bookName}</h2>
                            <p className="lead">{chapterTitle}</p>
                            <p className="u-mb-0">{teachers[0] && teachers[0].displayName}</p>
                            <p>{classTitle && classTitle}</p>
                        </div>
                    </div>
                </div>
            )}
            {votingGuidance && <VotingInstructions closePanel={toggleVotingGuidance} />}
        </div>
    )
}

export default BookHeader;
