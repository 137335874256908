import React, {useState, useEffect} from 'react';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import Task from '../components/Task';

const Todo = ({ books }) => {
    const [tasks,setTasks] = useState([])

    useEffect(() => {
        const newTasks = []
        books.map(book => {
            [...book.chapters, ...book.polls].map(item => {
                if (item.question) {
                    item.status !== 2 && newTasks.push(item)
                } else if (item.status >= CHAPTER_STATUS.writing)  {
                    newTasks.push(item)
                }
            })
        })
        setTasks(newTasks.sort(function(a,b){return new Date(b.createdAt) - new Date(a.createdAt);}))
    },[])

    if (books.length === 0) {
        return <p>Nothing to do at the moment!</p>
    }
    return tasks.map(item => {
        if (item.question) {
            return <Task key={item.id} poll={item} />
        } else {
            return <Task key={item.id} chapter={item} />
        }    
    })
}

export default Todo;