import React, { Fragment, useContext, useState } from 'react';
/*import { Link } from '@reach/router';*/
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { PupilAvatarViewer as Viewer } from '@axeedge/go-shared-components';

/*import noCover from '../../images/no-cover.png';*/
import { Loader } from '@axeedge/go-pupil-components';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import { MAIN_DASHBOARD_QUERY } from './services/graphql';
import { AuthContext } from '../../services/user/AuthProvider';
import styles from './Dashboard.module.scss';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import Todo from './scenes/Todo';
import Books from './scenes/Books';

import boomer from '../../images/loader-boomer.png'

const TABS = {
    active: 0,
    complete: 1
};

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState(TABS.active);
    const { data, error, loading } = useQuery(MAIN_DASHBOARD_QUERY, {
        fetchPolicy: 'cache-and-network'
    });

    const { currentUser: user } = useContext(AuthContext);

    if (loading || error) {
        return (
            <div className={styles.dashboard}>
                <div className={styles.dashboardMain}>
                    <div className='content-panel'>
                        {error && <p>{error.message}</p>}
                        {loading && <Loader />}
                    </div>
                </div>
                <div className={styles.dashboardSide}>
                    <div className={styles.dashboardSidePanel}>
                        <h2 className={styles.dashboardSidePanelTitle}>Hi, {user.firstName}</h2>
                        <p>Welcome to BoomMath!</p>
                    </div>
                </div>
            </div>
        )
    }

    const filteredChapters = () => {
        const filteredChapters = [];
        if (selectedTab === TABS.active) {
            data.currentStudent.mathProjects.forEach(p => {
                p.chapters.forEach(chapter => {
                    if (chapter.status < CHAPTER_STATUS.completed) {
                        if (filteredChapters.filter(e => e.id === p.id).length === 0) {
                            filteredChapters.push(p)
                        }
                    }
                })
                p.polls.forEach(poll =>{
                    if (poll.status < 2) {
                        if (filteredChapters.filter(e => e.id === p.id).length === 0) {
                            filteredChapters.push(p)
                        }
                    }
                })
            })
        }
        if (selectedTab === TABS.complete) {
            data.currentStudent.mathProjects.forEach(p => {
                p.chapters.forEach(chapter => {
                if (chapter.status === CHAPTER_STATUS.completed) {
                    if (filteredChapters.filter(e => e.id === p.id).length === 0) {
                        filteredChapters.push(p)
                    }
                }
                })
                p.polls.forEach(poll =>{
                    if (poll.status === 2) {
                        if (filteredChapters.filter(e => e.id === p.id).length === 0) {
                            filteredChapters.push(p)
                        }
                    }
                })
            })
        }
        return filteredChapters;
    }

    if (data && data.currentStudent && data.currentStudent.mathProjects) {
        return (
            <div className={styles.dashboard}>
                <div className={styles.dashboardMain}>
                        <Tabs noBorder={true} white={true}>
                            <Tab white={true} active={selectedTab === TABS.active} onClick={() => setSelectedTab(TABS.active)}>Active</Tab>
                            <Tab white={true} active={selectedTab === TABS.complete} onClick={() => setSelectedTab(TABS.complete)}>Complete</Tab>
                        </Tabs>
                        {selectedTab === TABS.active && <Todo books={filteredChapters()} />}
                        {selectedTab === TABS.complete && <Books chapters={filteredChapters()} />}
                </div>
                <div className={styles.dashboardSide}>
                    <div className={styles.dashboardSidePanel}>
                        <Fragment>
                            <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, "")}` : boomer} className={styles.dashBoomer} />
                            <h2 className={styles.dashboardSidePanelTitle}>Hi, {user.firstName}</h2>
                            <p>Welcome to BoomMath!</p>
                        </Fragment>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default Dashboard;
