import React, {useState, useContext} from 'react'
import styles from './Poll.module.scss'
import cx from 'classnames'
import { Button } from '@axeedge/go-pupil-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-pupil-components';
import {GET_POLL, SUBMIT_POLL} from './services/graphql'
import { AuthContext } from '../../services/user/AuthProvider';
import { AlertCircle, XCircle } from 'react-feather';
import boomer from '../../images/loader-boomer.png';
import { Link } from '@reach/router';
const Poll = ({pollId}) => {

    const [answer,setAnswer] = useState(null)
    const [comment, setComment] = useState('')
    const [formErrors, setFormErrors] = useState(null)
    const [pollSuccess, setPollSuccess] = useState(false)
    const { currentUser: user } = useContext(AuthContext);
    const { data, error, loading } = useQuery(GET_POLL, {
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        variables: {
            id: pollId
        }
    });

    const [submitPoll, { loading: submitting }] = useMutation(SUBMIT_POLL, {
        onCompleted: ({ createPollEntry }) => {
            if (createPollEntry.errors && createPollEntry.errors.length !== 0) {
                setFormErrors(createPollEntry.errors);
                return;
            } else {
                setPollSuccess(true)
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_POLL,
                variables: {
                    id: pollId
                }
            }
        ],
    })

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return (
                <Loader />
        )
    }

    const pEntry = data.poll.pollEntries.filter(pe => pe.author.id === user.id)[0];

    if (data) {
    return (
        <div className={styles.mainContainer}>
        <div className={styles.upper}>
            <Link to="/" className={styles.closeBtn}><span className={styles.closeBtnTxt}>Close</span> <XCircle /></Link>
        </div>
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.pollHeader}>
                    <h1 className={styles.pollHeading}>{data.poll.question}</h1>
                </div>
                {pollSuccess || pEntry && pEntry.status === 1 || data.poll.status === 2 ?
                    <>
                        <div className={styles.pollSuccess}>
                        <h2 className={styles.pollSubHeading}>{data.poll.status === 2 ? `This poll has ended${pEntry.status !== 1 ? ' - You did not vote' : ''}` : 'Thanks for submitting your entry'}.</h2>
                        </div>
                        <div className={styles.pollOptions}>
                            {data.poll.pollReactionSet.pollReactions.map(r => {
                                    return <div key={`reaction${r.id}`} className={cx(styles.pollOption, styles.pollOptionFade, pEntry && pEntry.pollReaction && pEntry.pollReaction.id === r.id && styles.pollOptionChosen)}><img className={styles.reactionImg} src={require(`../../images/${r.reaction}.png`)} /></div>
                            })}
                        </div>
                        {data.poll.allowComments &&
                            <>
                                <h2 className={styles.pollSubHeading}>Describe how you feel about the question:</h2>
                                <p className={styles.questionPara}>{pEntry.entry ? pEntry.entry : `You didn't leave an answer`}</p>
                            </>
                        }
                    </>
                :
                    <>
                        <div className={styles.pollOptions}>
                            {data.poll.pollReactionSet.pollReactions.map(r => {
                                return <div key={`reaction${r.id}`} className={cx(styles.pollOption, answer === r.id && styles.pollOptionSelected)} onClick={() => setAnswer(r.id)}><img className={styles.reactionImg} src={require(`../../images/${r.reaction}.png`)} /></div>
                            })}
                        </div>
                        {data.poll.allowComments &&
                            <>
                                <h2 className={styles.pollSubHeading}>Describe how you feel about the question:</h2>
                                <textarea className={styles.pollTextArea} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                            </>
                        }
                        <div className="u-mt-2">
                            {data.poll.allowComments ?
                                <Button disabled={submitting || !answer} onClick={() => submitPoll({
                                variables: {
                                    pollId,
                                    pollReactionId: answer,
                                    entry: comment,
                                }})}>{submitting ? 'Saving' : 'Poll Now!'}</Button>
                            :
                            <Button disabled={submitting || !answer} onClick={() => submitPoll({
                                variables: {
                                    pollId,
                                    pollReactionId: answer,
                                }})}>{submitting ? 'Saving' : 'Poll Now!'}</Button>
                            }
                            </div>
                    </>
                }
            </div>
            <div className={styles.aside}>
                <div className={styles.asideInner}>
                    <h3 className={styles.instructions}><AlertCircle style={{marginRight: 10}} /> Instructions</h3>
                    <p>Click on the icon and leave a comment to describe how you feel about the question.</p>
                    <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, "")}` : boomer} className={styles.instructionsBoomer} />
                </div>
            </div>
        </div>
    </div>
    )}
    return null;
}

export default Poll