import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { GET_MATH_PROJECT_CHAPTER} from './services/graphql';
import { Loader } from '@axeedge/go-pupil-components';
import BookHolder from './components/BookHolder';

const Book = ({ bookId }) => {

    const { data, error, loading } = useQuery(GET_MATH_PROJECT_CHAPTER, {
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        variables: {
            mathProjectChapterId: bookId
        }
    });


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return (
            <div className="content-panel">
                <Loader />
            </div>
        )
    }

    if (data.mathProjectChapter && data.mathProjectChapter.id) {
        return (

            <BookHolder chapterData={data.mathProjectChapter} />
        );
    }

    return null;
}

export default Book;
