import React, { Fragment } from 'react';
import cx from 'classnames';
import { Book, Edit3, Star, FileText, Info } from 'react-feather';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

const ChapterLinks = ({ closeMenu, chapter, selectedChapter, setSelectedChapter }) => {
    return(
        <Fragment>
            <ul>

                        <li>
                            <button
                                onClick={() => {
                                    closeMenu();
                                    setSelectedChapter(0)
                                }}
                                className={cx('bookmenu__link', {'bookmenu__link--active': selectedChapter === 0})}
                            >
                                {chapter.status < CHAPTER_STATUS.voting ? <Edit3 /> : chapter.status > CHAPTER_STATUS.voting_closed ? <FileText /> : <Star />}
                            </button>
                        </li>
                        <li>
                        <button
                            onClick={() => {
                                closeMenu();
                                setSelectedChapter(-1)
                            }}
                            className={cx('bookmenu__link', {'bookmenu__link--active': selectedChapter === -1})}
                        >
                            <Info />
                        </button>
                    </li>
                    
            </ul>
        </Fragment>
    )
}

export default ChapterLinks;
