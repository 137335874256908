import React from 'react';
import styles from './Header.module.scss';

const Header = ({ title, author }) => {
    return (
        <div className={styles.header}>
            <h2 className={styles.headerTitle}>{title}</h2>
            <h3 className={styles.headerSub}>My Entry</h3>
            {author && <h4 className={styles.headerAuthor}>{author}</h4>}
        </div>
    )
}

export default Header;
