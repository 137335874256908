// utils for book stuff

const countWords = str => {
    str = str.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
    str = str.replace(/[~`!@#$%^&*(){}[\];:"'<,.>?/\\|_+=-]/g, ""); // punctuation
    str = str.replace(/\s{2,}/g, " "); //2 or more space to 1
    str = str.replace(/\n /, "\n"); // exclude newline with a start spacing
    return str.trim().split(/\s+/).length;
}


export const updateEntry = (data, setEntry) => {
    let words = [];

    data.blocks.forEach((block) => {
        words.push(countWords(block.data.text));
    });

    setEntry({ text: data, wordCount: words.reduce((a, b) => a + b, 0) })
}


const escapeVocab = vocab => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

// https://stackoverflow.com/a/63849592
const highlightVocab = (text, vocab) => {
    let newText = text;
    const terms = vocab.map(v => escapeVocab(v.word));
    terms.sort((a, b) => b.length - a.length);
    let regEx = new RegExp(String.raw`\b(?:${terms.join('|')})\b`, 'gi');
    newText = newText.replace(regEx, (m) => `<span class='tagvocab'>${m}</span>`);
    return newText;
}

export const highlightEntry = (entryData, vocabWords) => {
    if (vocabWords.length > 0) {
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocabWords)
            }
        });
        return entryData
    }
    return entryData
    
}